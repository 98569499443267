import { HOMEPAGE_BLOCKS } from '@/constants/index';
import { getLocaleUrl, imageloader } from '@/helpers/index';
import _, { get, uniqueId } from 'lodash';
import Image from 'next/image';
import Link from 'next/link';
import { useRouter } from 'next/router';
import { memo, useRef } from 'react';
import { ChevronLeft, ChevronRight } from 'react-feather';
import { Autoplay, Navigation, Pagination } from 'swiper/modules';
import { Swiper, SwiperSlide } from 'swiper/react';

const { ARTICLE } = HOMEPAGE_BLOCKS;

function BannerCarousel({ banner }) {
  const swiperRef = useRef();
  const { locale } = useRouter();

  return (
    <Swiper
      ref={swiperRef}
      centeredSlides={true}
      autoplay={{
        delay: 10000,
        disableOnInteraction: false,
      }}
      loop={true}
      pagination={{
        clickable: true,
      }}
      navigation={true}
      modules={[Autoplay, Pagination, Navigation]}
      className="banner-slide"
    >
      {_.map(banner, (slide) => (
        <SwiperSlide key={uniqueId()}>
          <div className="slide-item">
            <div className="slide-item__hero">
              {get(slide, 'type') === ARTICLE ? (
                <Image
                  src={get(slide, 'value.image[0].value.webp')}
                  loader={imageloader}
                  sizes="(100vw, 560px)"
                  objectFit="cover"
                  layout="fill"
                  priority
                  alt={get(slide, 'value.alt_text' || 'banner-img')}
                />
              ) : (
                <Image
                  src={
                    get(slide, 'value.destination[0].image.url') ||
                    get(slide, 'value.destination[0].image.url')
                  }
                  loader={imageloader}
                  alt={get(slide, 'value.alt_text') || 'banner-img'}
                  sizes="(100vw, 560px)"
                  objectFit="cover"
                  layout="fill"
                  priority
                />
              )}
            </div>
            <div className="slide-item__content">
              <div className="content-wrapper">
                {get(slide, 'type') === ARTICLE && (
                  <Link
                    href={`/category/travel-guide/${_.split(
                      get(slide, 'value.post[0].value.category_slug'),
                      ',',
                      1
                    )}`}
                  >
                    <a>
                      <div className="article-category">
                        {_.split(
                          get(slide, 'value.post[0].value.post_category'),
                          ',',
                          1
                        )}
                      </div>
                    </a>
                  </Link>
                )}
                <a
                  href={getLocaleUrl(
                    locale,
                    get(slide, 'type') === ARTICLE
                      ? get(slide, 'value.post[0].value.post_name')
                      : `destination/${get(
                          slide,
                          'value.destination[0].slug',
                          'australia'
                        )}`
                  )}
                >
                  <h2 className="article-name">
                    {get(slide, 'type') === ARTICLE
                      ? get(slide, 'value.post[0].value.post_title')
                      : get(slide, 'value.destination[0].title', '')}
                  </h2>
                </a>
              </div>
            </div>
          </div>
        </SwiperSlide>
      ))}

      <>
        <button
          id="prevBtn"
          className="banner-slide__navigation banner-slide__navigation--prev"
          onClick={() => swiperRef.current.swiper.slidePrev()}
        >
          <ChevronLeft />
        </button>
        <button
          id="nextBtn"
          className="banner-slide__navigation banner-slide__navigation--next"
          onClick={() => swiperRef.current.swiper.slideNext()}
        >
          <ChevronRight />
        </button>
      </>
    </Swiper>
  );
}

export default memo(BannerCarousel);
